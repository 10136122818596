<template>
  <v-container class="mt-4" :fluid="$vuetify.breakpoint.mdOnly">
    <v-row>
      <v-col cols="12" class="py-0">
        <nota-fiscal-title
          title="Nota Fiscal de Consumidor"
          :authorized-at="authorizedAt"
          :back-to="{ name: 'nf-customer-list' }"
          :issueDate="issueDate"
          :number="value.number"
          :serie="value.serie"
        />
      </v-col>

      <v-expand-transition>
        <banner
          v-if="value.sefaz_id"
          :canceled="value.canceled"
          :nf-type="value.model"
          :pdf-url="value.pdf_url"
          :sefaz-id="value.sefaz_id"
          :value="banner"
          :xml-url="value.xml_url"
        />
      </v-expand-transition>

      <v-col cols="12">
        <v-card outlined :loading="sending">
          <v-form ref="form">
            <recipient
              v-if="!readonly || recipient"
              optional
              label="Cliente"
              :readonly="readonly"
              v-model="recipient"
            />

            <products
              price-readonly
              ref="products"
              class="pt-0"
              nfe-operation="venda"
              :readonly="readonly"
              :fiscal-regime="companyFiscalRegime"
              :cfop-applications="['nfce']"
              :total.sync="totalLines"
              v-model="products"
            />

            <nfce-payments
              class="pt-0"
              :readonly="readonly"
              :discount.sync="totalDiscount"
              :total="totalNf"
              v-model="payments"
            />

            <additional-data
              class="pt-0"
              :readonly="readonly"
              v-show="false"
              v-model="notes"
            />

            <total
              class="pt-0"
              :total-lines="totalLines"
              :total-discount="totalDiscount"
              :total-shipping="totalShipping"
              :tax-base-icms="taxBaseIcms"
              :total-icms="totalIcms"
              :tax-base-icms-st="taxBaseIcmsSt"
              :total-icms-st="totalIcmsSt"
              :total-ipi="totalIpi"
              :total-pis="totalPis"
              :total-cofins="totalCofins"
              :total="totalNf"
            />
          </v-form>

          <v-card-actions
            class="py-12 px-sm-8 justify-center justify-sm-space-between"
          >
            <v-btn outlined class="d-none d-sm-flex" @click="$router.back()">
              Voltar
            </v-btn>

            <div v-if="!readonly">
              <v-tooltip bottom v-if="!!value.id">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    tile
                    color="error"
                    class="mr-5"
                    v-bind="attrs"
                    v-on="on"
                    @click="deleteNfe"
                  >
                    <v-icon>mdi-trash-can</v-icon>
                  </v-btn>
                </template>

                <span>Remover Nota Fiscal</span>
              </v-tooltip>

              <v-btn
                large
                color="success"
                :loading="sending"
                @click="saveAndSend"
              >
                <v-icon left>mdi-send</v-icon>
                Emitir NFC-e
              </v-btn>
            </div>

            <v-btn
              v-else-if="!value.canceled"
              color="error"
              class="ml-4"
              @click="showCancelDialog = true"
            >
              <v-icon left>mdi-cancel</v-icon>
              Cancelar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <nota-fiscal-cancel
      :nf-id="value.id"
      :serie="value.serie"
      :number="value.number"
      v-model="showCancelDialog"
      @update:canceled="canceledUpdated"
    />
  </v-container>
</template>

<script>
import Nfe from "@/mixins/Nfe";
import GoTo from "@/mixins/GoTo";
import $store from "@/store";
import { HttpError, ValidationError } from "@/components/Error";
import NotaFiscalTitle from "@/components/NotaFiscal/Title";
import Banner from "@/components/NotaFiscal/Banner";
import Recipient from "@/components/NotaFiscal/Recipient";
import Products from "@/components/NotaFiscal/Products";
import NfcePayments from "@/components/NotaFiscal/NfcePayments";
import AdditionalData from "@/components/NotaFiscal/AdditionalData";
import Total from "@/components/NotaFiscal/Total";
import NotaFiscalCancel from "@/components/NotaFiscal/Cancel";

export default {
  name: "NotasFiscaisCustomersView",
  metaInfo() {
    return {
      title: "Nova Nota Fiscal de Consumidor",
    };
  },
  mixins: [Nfe, GoTo],
  components: {
    NotaFiscalTitle,
    Banner,
    Recipient,
    Products,
    NfcePayments,
    AdditionalData,
    Total,
    NotaFiscalCancel,
  },
  data: () => ({
    sending: false,
    showCancelDialog: false,
    value: {
      model: "nfce",
      point_of_sale: "store",
    },

    // nfe fields
    operation: {
      description: "Venda",
      type: {
        value: "venda",
      },
    },
    products: [],
    recipient: null,
    payments: [],
    shipping: {
      type: "no_shipping",
    },
    notes: {},
  }),
  computed: {
    readonly() {
      return !!this.value.sefaz_id;
    },
    banner() {
      const { id, status, sefaz_id: sefazId } = this.value;
      if (id) {
        if (status === "canceled") return "canceled";
        if (!sefazId) return "send";
        return "sent";
      }
      return "";
    },
    companyFiscalRegime() {
      return this.company?.fiscalRegime || "simple";
    },
  },
  watch: {
    company() {
      if (!this.value.id) return;

      this.$nextTick().then(() => {
        // when a new company is selected, go back to list
        this.$router.push({ name: "nf-customer-list" });
      });
    },
  },
  methods: {
    validate() {
      const { form, products } = this.$refs;

      let errs;

      if (form.validate()) {
        const valid = (errs = products.validate()) === true;
        if (valid) {
          return true;
        }
      } else {
        this.notifyError("Alguns campos obrigatórios não foram preenchidos 😢");
        return false;
      }

      this.notifyError(
        "Encontramos alguns problemas ao tentar salvar a NF 😢<ul><li>" +
          errs.join("</li><li>") +
          "</li></ul>"
      );
      return false;
    },
    async saveNfce() {
      if (!this.validate()) {
        throw new ValidationError();
      }

      let method,
        url = "/v1/nfe/documents";
      if (!this.value.id) {
        const { serie, number } = await this.getNextNfNumber();
        this.value.serie = serie;
        this.value.number = number;
        method = "post";
      } else {
        url += `/${this.value.id}`;
        method = "put";
      }

      const {
        data: { data: nfe },
      } = await this.$http[method](url, this.oldNfeFormat);

      this.loadNfe(nfe.id);
      return { nfe, isNew: method === "post" };
    },
    async saveAndSend() {
      this.sending = true;

      try {
        const { nfe, isNew } = await this.saveNfce();
        await this.sendNf(nfe.id);

        if (isNew) {
          this.$router.push({
            name: "nf-customer-view",
            params: { id: nfe.id },
          });
        } else {
          this.$nextTick().then(() => {
            this.goTo(0);
          });
        }

        this.notifySuccess("Nota Fiscal transmitida com sucesso 🎉");
      } catch (err) {
        if (err instanceof ValidationError) {
          return;
        }
        if (err instanceof HttpError) {
          if (
            err.code === "invalid_nfe" ||
            err.code.startsWith("invalid_nfe_error_")
          ) {
            this.notifyError(err.msg, 12000);
            return;
          } else if (err.code === "nfe_already_sent") {
            await this.loadNfe(this.value.id);
            this.goTo(0);
            return;
          }
          this.$sentry.captureException(err);
          this.notifyError("Ocorreu um erro ao transmitir a NFC-e 😢");
          return;
        }

        console.error(err);
        this.$sentry.captureException(err);
      } finally {
        this.sending = false;
      }
    },
    async deleteNfe() {
      const { id, serie, number } = this.value;

      const deleted = await this.deleteNf(id, serie, number);
      if (deleted) {
        this.$router.push({ name: "nf-customer-list" });
      }
    },
    async canceledUpdated(v) {
      this.$set(this.value, "canceled", v);
      await this.addProductTransactions({
        model: this.value.model,
        serie: this.value.serie,
        number: this.value.number,
        operationType: this._operationType,
        canceled: !!v,
        transactionDate: v.canceled_at,
        lines: this.products.map((line) => ({
          product_id: line.product?.id,
          quantity: line.quantity,
        })),
      });
      this.$nextTick().then(() => {
        this.goTo(0);
      });
    },
    async loadNfe(id) {
      try {
        await this.getNf(id);
      } catch (err) {
        this.$router.push({ name: "nf-customer-list" });
      }
    },
  },
  mounted() {
    if (this.value.id) {
      let oldNFeFormat = this.convertNFe(this.value);
      this.loadNf(oldNFeFormat);
    }
  },
  async beforeRouteEnter(to, from, next) {
    const { id } = to.params;
    if (!id) {
      next();
      return;
    }

    try {
      let nfe = await $store.dispatch("nfes/get", id);
      next((vm) => {
        vm.value = nfe;
      });
    } catch (err) {
      next({ name: "nf-customer-list" });
    }
  },
  async beforeRouteUpdate(to, from, next) {
    const { id } = to.params;
    if (!id) {
      next();
      return;
    }

    try {
      this.value = await this.$store.dispatch("nfes/get", id);
      next();
    } catch (err) {
      next({ name: "nf-customer-list" });
    }
  },
};
</script>
