<template>
  <div>
    <v-card-title class="section-title">
      Dados Sobre o Pagamento
    </v-card-title>

    <v-card-text>
      <v-row>
        <v-col cols="12" md="3" sm="4">
          <number-field
            outlined
            hide-details
            label="Desconto"
            prefix="R$"
            :readonly="readonly"
            :value="discount"
            @input="discountChanged"
          />
        </v-col>

        <v-col cols="12" md="3" sm="4">
          <number-field
            outlined
            readonly
            hide-details
            label="Valor a Pagar"
            prefix="R$"
            :disabled="!readonly"
            :value="total"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" md="3" sm="4">
          <v-select
            outlined
            hide-details
            label="Condições de Pagamento"
            :readonly="readonly"
            :required="!readonly"
            :items="options"
            :value="numberInstallments"
            @input="updateInstallments"
          />
        </v-col>

        <template v-if="!showInstallment && local.length > 0">
          <template v-if="numberInstallments === 0">
            <v-col cols="12" sm="3">
              <v-text-field
                outlined
                required
                disabled
                hide-details
                label="Vencimento"
                :value="$moment(local[0].dueDate).format('L')"
              />
            </v-col>

            <v-col cols="12" md="3" sm="5">
              <v-select
                outlined
                required
                hide-details="auto"
                label="Forma de Pagamento"
                :items="methods"
                :rules="[notEmptyRule]"
                :value="local[0].method"
                @input="updateValueKeyIndex(0, 'method', $event)"
              />
            </v-col>
          </template>

          <v-col v-else cols="12" sm="3">
            <date-picker-field
              outlined
              required
              hide-details
              label="Vencimento"
              :min="new Date().toISOString().substr(0, 10)"
              :value="local[0].dueDate"
              @input="updateValueKeyIndex(0, 'dueDate', $event)"
            />
          </v-col>
        </template>
      </v-row>

      <v-row v-if="showInstallment">
        <v-col cols="12">
          <v-card outlined>
            <v-card-text>
              <v-row no-gutters>
                <v-col
                  cols="2"
                  align-self="center"
                  class="d-none d-sm-flex px-3 text-center"
                >
                  <span class="secondary--text text--lighten-2 text-button">
                    Parcela
                  </span>
                </v-col>

                <v-col cols="6" md="2" sm="3" align-self="center" class="px-3">
                  <span class="secondary--text text--lighten-2 text-button">
                    Vencimento
                  </span>
                </v-col>

                <v-col cols="6" md="2" sm="3" align-self="center" class="px-3">
                  <span class="secondary--text text--lighten-2 text-button">
                    Valor
                  </span>
                </v-col>
              </v-row>

              <v-row
                no-gutters
                class="mb-1"
                v-for="(installment, i) in local"
                :key="i"
              >
                <v-col
                  cols="2"
                  align-self="center"
                  class="d-none d-sm-flex px-3 text-center"
                >
                  <span class="secondary--text text--lighten-2 text-button">
                    {{ i + 1 }} / {{ local.length }}
                  </span>
                </v-col>

                <v-col cols="6" md="2" sm="3" class="px-3">
                  <date-picker-field
                    outlined
                    dense
                    hide-details
                    x-small
                    :readonly="readonly"
                    :required="!readonly"
                    :min="new Date().toISOString().substr(0, 10)"
                    :value="installment.dueDate"
                    @input="updateValueKeyIndex(i, 'dueDate', $event)"
                  />
                </v-col>

                <v-col cols="6" md="2" sm="3" class="px-3">
                  <number-field
                    outlined
                    dense
                    hide-details
                    prefix="R$"
                    :rules="[minDecimalRule(0.01, installment.amount)]"
                    :readonly="readonly"
                    :required="!readonly"
                    :value="installment.amount"
                    @input="updateValueKeyIndex(i, 'amount', $event)"
                  />
                </v-col>

                <template v-if="i === 0 || installment.paid">
                  <v-col cols="auto" class="px-3">
                    <v-switch
                      dense
                      hide-details
                      label="Pago"
                      class="mt-2"
                      :readonly="readonly"
                      :input-value="installment.paid"
                      @change="updateValueKeyIndex(i, 'paid', $event)"
                    />
                  </v-col>

                  <v-col
                    v-if="showSelectMethod(installment)"
                    cols="12"
                    offset-md="0"
                    sm="4"
                    offset-sm="8"
                    class="px-3"
                  >
                    <v-select
                      outlined
                      dense
                      hide-details
                      placeholder="Forma de Pagamento"
                      :items="methods"
                      :readonly="readonly"
                      :required="!readonly"
                      :rules="[notEmptyRule]"
                      :value="installment.method"
                      @input="updateValueKeyIndex(i, 'method', $event)"
                    />
                  </v-col>
                </template>
              </v-row>

              <v-row v-if="!totalInstallments.equals(totalDecimal)">
                <v-col cols="12">
                  <v-alert outlined text type="error">
                    Soma das parcelas difere em
                    <currency
                      class="font-weight-bold"
                      :value="totalDecimal.sub(totalInstallments).times(-1)"
                    />
                    no valor da Nota Fiscal.
                  </v-alert>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
  </div>
</template>

<script>
import Decimal from "decimal.js";
import UpdateModel from "@/mixins/UpdateModel";
import Rules from "@/mixins/Rules";
import DatePickerField from "@/components/DatePickerField.vue";
import NumberField from "@/components/NumberField";
import Currency from "@/components/Currency";

export default {
  name: "NotaFiscalNfcePayments",
  mixins: [UpdateModel, Rules],
  components: {
    DatePickerField,
    NumberField,
    Currency,
  },
  props: {
    readonly: Boolean,
    value: Array,
    total: Number,
    discount: Number,
  },
  data: () => ({
    numberInstallments: 0,
  }),
  computed: {
    local() {
      return this.value || [];
    },
    showInstallment() {
      return (
        this.local.length > 1 || (this.local.length === 1 && !!this.local[0].id)
      );
    },
    options() {
      let options = [{ text: "À vista", value: 0 }];
      for (let i = 1; i <= 12; i++) {
        options.push({ text: i + "x", value: i });
      }
      return options;
    },
    methods() {
      return [
        { text: "Dinheiro", value: "cash" },
        { text: "Cheque", value: "check" },
        { text: "Cartão de Crédito", value: "credit_card" },
        { text: "Cartão de Débito", value: "debit_card" },
        { text: "Crédito Loja", value: "store_credit" },
        { text: "Vale Presente", value: "gift_card" },
        { text: "Boleto Bancário", value: "boleto" },
        { text: "Depósito", value: "deposit" },
        { text: "Pix", value: "pix" },
        { text: "Outros", value: "other" },
      ];
    },
    totalDecimal() {
      return new Decimal(this.total);
    },
    totalInstallments() {
      return this.local.reduce((total, installment) => {
        return total.add(installment.amount || 0);
      }, new Decimal(0));
    },
  },
  watch: {
    value(v) {
      if (v.length === 1 && v[0].paid) {
        this.numberInstallments = 0;
      } else {
        this.numberInstallments = v.length;
      }
    },
    total() {
      if (this.totalInstallments.equals(this.totalDecimal)) return;
      const installments = this.installmentAmounts();
      let items = [];

      for (let i = 0; i < this.local.length; i++) {
        const item = this.local[i];
        const installment = installments[i];
        items.push({ ...item, amount: installment });
      }
      this.$emit("input", items);
    },
  },
  methods: {
    noInstallments() {
      this.$emit("input", [
        {
          dueDate: this.$moment().format("YYYY-MM-DD"),
          amount: this.totalDecimal.toNumber(),
          paid: true,
        },
      ]);
    },
    installmentAmounts(n) {
      if (!n) n = this.local.length;

      let installments = [];
      let total = new Decimal(0);
      const perInstallment = this.totalDecimal.div(n).toDecimalPlaces(2);

      for (let i = 0; i < n; i++) {
        let amountDecimal;
        if (i == n - 1) {
          amountDecimal = this.totalDecimal.sub(total);
        } else {
          amountDecimal = perInstallment;
        }
        installments.push(amountDecimal.toNumber());
        total = total.add(perInstallment);
      }
      return installments;
    },
    updateInstallments(n) {
      if (n === 0) {
        this.noInstallments();
        return;
      }

      let items = [];
      const amounts = this.installmentAmounts(n);

      for (let i = 0; i < n; i++) {
        items.push({
          dueDate: this.$moment()
            .add(i + 1, "month")
            .format("YYYY-MM-DD"),
          amount: amounts[i],
          paid: false,
        });
      }
      this.$emit("input", items);
    },
    showSelectMethod(v) {
      const { method } = v;
      return v.paid && method !== "other" && (!this.readonly || !!method);
    },
    discountChanged(v) {
      const dec = new Decimal(v || 0);
      this.$emit("update:discount", dec.toNumber());
    },
  },
  created() {
    if (this.local.length === 0) {
      this.noInstallments();
    }
    if (!this.discount) {
      this.discountChanged(0);
    }
  },
};
</script>
